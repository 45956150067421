<template>
  <div class="order-list-wrapper">
    <en-table-layout ref="tableLayout" :tableData="pageData.data" :loading="loading" :span-method="orderSpanMethod"
      @sort-change="handleSortChange">
      <template slot="header">
        <el-tabs v-model="params.self_goods" type="card" @tab-click="handleClick">
          <el-tab-pane v-for="(item, index) in navList" :key="index" :label="item.navigation_name"
            :name="item.navigation_id" :disabled="loading"></el-tab-pane>
        </el-tabs>
      </template>
      <template slot="toolbar">
        <el-form-item label="发货日期" class="col-auto">
          <el-date-picker style="width: 220px" v-model="advancedForm.order_time_range" type="daterange" align="center"
            size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>

        <el-form-item class="col-auto" size="medium">
          <el-input style="width: 300px" v-model.trim="advancedForm.keywords" placeholder="请输入关键词" clearable
            size="medium">
            <el-select style="width: 130px;" @change="changeType" v-model="advancedForm.search_type" slot="prepend"
              size="medium">
              <el-option label="商品名称" value="goods_name"></el-option>
              <el-option label="商品编号" value="goods_sn"></el-option>
              <el-option label="所属卡型名称" value="card_name"></el-option>
              <el-option v-show="params.self_goods == '3'" label="供应商名称" value="seller_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <div class="col-auto">
          <el-button @click="searchEvent();" size="small" type="primary">搜索</el-button>
          <el-button @click="exportOrder();" size="small" type="primary"
            :class="[submitImportCardKeysFlag ? 'publishOnlineDialogDisableOk' : '']">导出</el-button>
        </div>
      </template>
      <template slot="table-columns">
        <el-table-column label="商品名称" fixed="left" prop="goods_name" show-overflow-tooltip width="200">
          <template slot-scope="{row}">{{ row.goods_name && row.goods_name.replaceAll('amp;', '').replaceAll(' ',
            '&nbsp;')
          }}</template>
        </el-table-column>
        <el-table-column label="商品编号" prop="sn" width="180" fixed="left" />
        <el-table-column label="市场价" prop="mktprice" width="90" fixed="left">
          <template slot-scope="{row}">{{ row.mktprice }}</template>
        </el-table-column>
        <el-table-column v-if="parentLogin === 1" label="分销价" width="90" prop="enterprise_revise_price">
          <template slot-scope="{row}">{{ row.enterprise_revise_price }}</template>
        </el-table-column>
        <el-table-column v-if="parentLogin === 1" label="会员价" width="90" prop="price">
          <template slot-scope="{row}">{{ row.price }}</template>
        </el-table-column>
        <el-table-column label="可售库存数" prop="enable_quantity" width="100" />
        <el-table-column label="已发货数" sortable="custom" prop="ship_sum" width="110" />
        <el-table-column label="待发货数" sortable="custom" prop="stay_shipp_sum" width="110" />
        <el-table-column show-overflow-tooltip v-if="params.self_goods == 3 && $store.getters.isSupplierName"
          label="供应商名称" prop="seller_name" width="110" />
        <el-table-column label="状态" width="80">
          <template slot-scope="{row}">
            <span>{{ row.shop_goods_status }}</span>
          </template>
        </el-table-column>
        <el-table-column label="匹配礼包数" prop="combo_sum" width="100" />
        <el-table-column label="归属卡型" show-overflow-tooltip prop="card_name" width="120" />
        <el-table-column label="卡型可选套餐数" prop="combo_num" width="130" />
        <el-table-column label="卡号总数" prop="key_num" width="80" />
        <el-table-column label="已兑换数" prop="exchange_num" width="80" />
        <el-table-column label="已激活数" prop="active_num" width="80" />
        <el-table-column label="兑换占比" prop="exchange_format" width="80" />
        <el-table-column label="采购建议" width="160" prop="buyer_offer" fixed="right" />
      </template>

      <el-pagination slot="pagination" @current-change="handlePageCurrentChange" :current-page="pageData.page_no"
        :page-size="pageData.page_size" background :total="pageData.data_total" />
    </en-table-layout>
  </div>
</template>
  
<script>
import * as API_goods from "@/api/goods";
import { mergesHandleDownload } from "@/utils";
import EnTableLayout from "../../../../../ui-components/TableLayout/src/main";
import * as API_Floor from "@/api/floor";
export default {
  name: "shopStockup",
  components: {
    EnTableLayout
  },
  data () {
    return {
      submitImportCardKeysFlag: false,//重复提交
      isCancelBtn: null,
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
        self_goods: "2"
      },
      /**是否是企业跳转过来 */
      parentLogin: 0,
      navList: [],
      /** 列表分页数据 */
      pageData: { data: [] },
      /** 高级搜索数据 */
      advancedForm: {
        order_time_range: [],
        search_type: "goods_name",
        keywords: '',
      },
      merges: []//表格导出合并
    };
  },
  created () {
    this.getNavList();
    API_goods.getLoginType().then(res => {
      this.parentLogin = res.parentLogin;
    });
  },
  mounted () {
    this.$nextTick(() => {
      this.GET_OrderList();
    });
  },
  methods: {
    changeType () {
      this.advancedForm.keywords = '';
    },
    //表格排序方法
    handleSortChange ({ prop, order }) {
      switch (prop) {
        case "ship_sum": //已发货数
          this.params.sort_name = "ship_sum";
          break;
        default:
          //待发货数
          this.params.sort_name = "stay_shipp_sum";
          break;
      }
      if (order) {
        this.params.sort_rule = order.includes("asc") ? "asc" : "desc";
      } else {
        this.params.sort_rule = "";
        this.params.sort_name = "";
      }
      this.GET_OrderList();
    },
    // 标签切换
    handleClick (tab) {
      this.params = {
        page_no: 1,
        page_size: 20,
        self_goods: tab.name
      };
      this.advancedForm.keywords = ''
      this.advancedForm.search_type = "goods_name";
      // 将重新绘制表格放到标签切换的时候
      this.$nextTick(() => {
        this.$refs.tableLayout.$refs.table.doLayout();
      });
      this.GET_OrderList();
    },
    getNavList () {
      API_Floor.getNav().then(res => {
        this.navList = res.map(item => {
          switch (item.navigation_id) {
            case 65: //京东馆
              item.navigation_id = "2";
              break;
            case 63: //企业自有商品
              item.navigation_id = "1";
              break;
            case 66: //精选商品（供应商）
              item.navigation_id = "3";
              break;
          }
          return item;
        });
      });
    },
    //合并单元格
    orderSpanMethod ({ row, column, rowIndex, columnIndex }) {
      let uum = 0;//是否显示分销价、会员价
      this.parentLogin === 1 ? uum = 0 : uum = 2 //是否显示分销价、会员价
      if (columnIndex > 9 - uum && columnIndex < 16 && column.label !== '匹配礼包数' && column.label !== '采购建议' || column.label == '兑换占比') {
        return {
          rowspan: 1,
          colspan: 1
        };
      } else {
        return row.span;
      }
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_OrderList();
    },

    /** 高级搜索事件触发 */
    searchEvent () {
      this.params.page_no = 1;
      this.GET_OrderList();
    },
    GET_OrderList () {
      let params = {
        ...this.params
      };
      if (//如果搜索条件有时间查询
        this.advancedForm.order_time_range &&
        this.advancedForm.order_time_range.length
      ) {
        if (typeof this.advancedForm.order_time_range[0] === "string") {
          params.ship_start =
            new Date(this.advancedForm.order_time_range[0]).getTime() / 1000;
          this.params.ship_end =
            new Date(this.advancedForm.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          params.ship_start =
            this.advancedForm.order_time_range[0].getTime() / 1000;
          params.ship_end =
            this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
        }
      }
      if (this.advancedForm.search_type) {//判断是否有模糊查询
        params[this.advancedForm.search_type] = this.advancedForm.keywords;
      }
      this.loading = true;
      API_goods.getGoodsStockUp(params).then(res => {
        this.loading = false;
        this.pageData = res;
        this.pageData.data = this.formatSkuList(res.data);
      });
    },
    // 格式化表格数据
    formatSkuList (data) {
      this.merges = [];
      const results = [];
      data.forEach(item => {
        let sku_list = item.card_list_msg;
        // if (!sku_list) return;
        if (sku_list) {//有礼包
          results.push(
            ...sku_list.map((row, index) => {
              let span = {
                rowspan: 0,
                colspan: 0
              };
              if (index === 0) {
                span = {
                  rowspan: sku_list.length,
                  colspan: 1
                };
              }
              return {
                span,
                goods_name: item.goods_name || "", //商品名称
                sn: item.sn || "", //商品编号
                mktprice: '￥' + item.mktprice || "", //市场价
                enterprise_revise_price: '￥' + item.enterprise_revise_price, //分销价
                price: '￥' + item.price, //会员价
                enable_quantity: this.params.self_goods == 2 ? item.enable_quantity > 0 ? '有货' : '无货' : item.enable_quantity, //可售库存数
                ship_sum: item.ship_sum ? item.ship_sum : (this.params.self_goods == 2 ? "——" : 0), //已发货数
                stay_shipp_sum: item.stay_shipp_sum ? item.stay_shipp_sum : (this.params.self_goods == 2 ? "——" : 0), //待发货数
                seller_name: item.seller_name && this.$store.getters.isSupplierName ? item.seller_name : "", //供应商名称
                shop_goods_status: item.shop_goods_status === 0 ? '下架' : '上架', //状态
                combo_sum: item.combo_sum, //匹配礼包数
                card_name: row.card_name, //归属卡型
                combo_num: row.combo_num, //卡型可选套参数
                key_num: row.key_num, //卡号总数
                exchange_num: row.exchange_num, //已兑换数
                active_num: row.active_num, //已激活数
                exchange_format: row.exchange_format
                  ? (row.exchange_format * 100).toFixed(2) + "%"
                  : "", //兑换比例
                buyer_offer: item.buyer_offer //采购建议
              };
            })
          );
        } else {//无礼包
          results.push(
            {
              span: { rowspan: 1, colspan: 1 },
              goods_name: item.goods_name || "", //商品名称
              sn: item.sn || "", //商品编号
              mktprice: '￥' + item.mktprice || "", //市场价
              enterprise_revise_price: '￥' + item.enterprise_revise_price, //分销价
              price: '￥' + item.price, //会员价
              enable_quantity: this.params.self_goods == 2 ? item.enable_quantity > 0 ? '有货' : '无货' : item.enable_quantity, //可售库存数
              ship_sum: item.ship_sum ? item.ship_sum : (this.params.self_goods == 2 ? "——" : 0), //已发货数
              stay_shipp_sum: item.stay_shipp_sum ? item.stay_shipp_sum : (this.params.self_goods == 2 ? "——" : 0), //待发货数
              seller_name: item.seller_name && this.$store.getters.isSupplierName ? item.seller_name : "", //供应商名称
              shop_goods_status: item.shop_goods_status === 0 ? '下架' : '上架', //状态
              combo_sum: item.combo_sum, //匹配礼包数
              card_name: '', //归属卡型
              combo_num: '', //卡型可选套参数
              key_num: '', //卡号总数
              exchange_num: '', //已兑换数
              active_num: '', //已激活数
              exchange_format: '', //兑换比例
              buyer_offer: item.buyer_offer //采购建议
            }
          )
        }
      });
      //需要合并的单元格
      results.forEach((m, index) => {
        let uum = 0;
        this.parentLogin === 1 ? uum = 0 : uum = 2 //是否显示分销价、会员价
        this.params.self_goods == 3 && this.$store.getters.isSupplierName ? uum : uum += 1 //是否显示供应商名称
        if (m.span.rowspan > 1) {
          for (let i = 0; i <= 18 - uum; i++) {
            if (i < 11 - uum || i == 18 - uum - 1) {
              this.merges.push(
                { s: { r: index + 1, c: i }, e: { r: index + m.span.rowspan, c: i } }
              )
            }
          }
        }
      })
      return results;
    },
    /** 导出订单 */
    exportOrder () {
      if (this.submitImportCardKeysFlag) return
      this.submitImportCardKeysFlag = true
      let header = [
        "商品名称",
        "商品编号",
        "市场价",
        "分销价",
        "会员价",
        "可售库存数",
        "已发货数",
        "待发货数",
        "供应商名称",
        "状态",
        "匹配礼包数",
        "归属卡型",
        "卡型可选套餐数",
        "卡号总数",
        "已兑换数",
        "已激活数",
        "兑换比例",
        "采购建议"
      ];
      let values = [
        "goods_name",
        "sn",
        "mktprice",
        "enterprise_revise_price", //分销价
        "price", //会员价
        "enable_quantity",
        "ship_sum",
        "stay_shipp_sum",
        "seller_name",
        "shop_goods_status",
        "combo_sum",
        "card_name",
        "combo_num",
        "key_num",
        "exchange_num",
        "active_num",
        "exchange_format",
        "buyer_offer"
      ];
      if (this.parentLogin !== 1) {//是否是企业跳转过来 不是就删除分销价、会员价
        header = header.filter(item => item != "分销价")
        header = header.filter(item => item != "会员价")
        values = values.filter(item => item != "enterprise_revise_price")
        values = values.filter(item => item != "price")
      }
      if (this.params.self_goods != 3 || !this.$store.getters.isSupplierName) {//判断店铺是否拥有显示供应商名称的权限
        header = header.filter(item => item != "供应商名称")
        values = values.filter(item => item != "seller_name")
      }
      const obj = {};
      if (this.search_type) {
        obj[this.search_type] = this.advancedForm.keywords;
      }
      this.params = {
        ...this.params,
        ...this.advancedForm,
        ...obj
      };
      delete this.params.ship_start;
      delete this.params.ship_end;
      //处理时间控件查询
      if (
        this.advancedForm.order_time_range &&
        this.advancedForm.order_time_range.length
      ) {
        if (typeof this.advancedForm.order_time_range[0] === "string") {
          this.params.ship_start =
            new Date(this.advancedForm.order_time_range[0]).getTime() / 1000;
          this.params.ship_end =
            new Date(this.advancedForm.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.ship_start =
            this.advancedForm.order_time_range[0].getTime() / 1000;
          this.params.ship_end =
            this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
        }
      }
      delete this.params.keywords;
      delete this.params.order_time_range;
      this.params.page_no = 1;
      this.params.page_size = 99999;
      this.loading = true;

      delete this.params.keywords;
      delete this.params.type;

      API_goods.getGoodsStockUp(this.params).then(res => {
        this.submitImportCardKeysFlag = false
        this.loading = false;
        let data = this.formatSkuList(res.data);
        mergesHandleDownload(data, header, values, "备货数据_" + ((Date.now()) / 1000).toFixed(0), true, this.merges);
      });
    }
  }
};
</script>
  
<style lang="scss" scoped>
.goods-cover-wrapper .cell {
  padding-left: 0 !important;
}

.remark-box {
  max-width: 150px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.publishOnlineDialogDisableOk {
  ::v-deep .el-button--primary {
    background-color: #e6e6e6 !important;
    border-color: #e6e6e6 !important;
    color: #999 !important;
  }
}
</style>
  